import * as React from "react";
import PropTypes from "prop-types";
import { Link } from 'gatsby'

import arrowLeft from "../img/arrow-left-primary.svg"

const Back = ({
  title,
  url,
  className = "test"
}) => (
  <div className="block">
    <Link
      className={`button is-primary-invert ${className}`}
      to={url}
    >
      <div className="icon mr-3">
        <img className="arrow-left" src={arrowLeft} alt="Navigate" />
      </div>
      Back to { title }
    </Link>
  </div>
);

Back.propTypes = {
  back: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      className: PropTypes.string,
    })
  ),
};

export default Back;
