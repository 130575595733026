import React from "react";
import PropTypes from "prop-types";
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, { HTMLContent } from "../components/Content";
import Back from "../components/Back";
import HashtagHidden from "../components/HashtagHidden";

// eslint-disable-next-line
export const NewsPostTemplate = ({
  content,
  contentComponent,
  description,
  image,
  title,
  hashtags
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="section mb-6">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description ?? `Insights on Kamsa - ${title}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="HRLola"/>
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description ?? `Insights on Kamsa - ${title}`}/>
          <meta name="twitter:image" content={"https://www.kamsa.co" + getSrc(image)} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description ?? `Insights on Kamsa - ${title}`}/>
          <meta property="og:image" content={"https://www.kamsa.co" + getSrc(image)} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
        </Helmet>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="block has-text-centered my-6">
              <PreviewCompatibleImage
                imageInfo={{
                  image,
                  alt: `featured image thumbnail for post ${title}`,
                }}
              />
            </div>
            <div className="block has-text-centered mb-6 px-6 no-padding-mobile">
              <h2 className="subtitle is-h2 has-text-weight-bold">{ title }</h2>
              { description ?? <h4 className="is-h4">{ description }</h4> }
            </div>

            <PostContent content={content} />

            <div className="block has-text-centered">
              <Back
                title="Insights"
                url="/insights"
              />
            </div>
          </div>
        </div>
      </div>
      <HashtagHidden hashtags={hashtags} />
    </section>
  );
};

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  hashtags: PropTypes.array,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const NewsPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout pageImage={post.frontmatter.featuredimage}>
      <NewsPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        hashtags={post.frontmatter.hashtags ?? []}
      />
    </Layout>
  );
};

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        hashtags {
          title
        }
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
